/* This CSS code hides scrollbars everywhere. */

* {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox 64 */
}

::-webkit-scrollbar {
  display: none;
}
